//WIDOK DESKTOP
@media (min-width: 1025px){
  $mainWidth: 90vw;
  header{
    position: relative;
    margin-bottom: 1.5em;
  }
  .headerSearchbar {
    //display: inline;
    width: 500px;
    line-height: 2em;
    display: flex;
    align-items: center;
    .magnifyingGlass{
      height: 1.4em;
      position: relative;
      right: 7em;
      bottom: 0.3em;
    }
    input {
      width: 50%;
      height: 2em;
      margin: 30px auto;
      border: none;
      border-bottom: 4px solid black;
      &:focus{
        outline: none;
      }
    }
form {
  width: 500px;
    line-height: 2em;
    display: flex;
    align-items: center;      
}

  }
  .mobileMenuWrapper{
    display: none;
  }
  .headerInfo{
    display: none;
  }
  .headerInfoWrapper{
    position: relative;
  }
  .headerInfoDesktop {
    position: absolute;
    top: -2.9em;
    right: 0%;
    width: 11em;
    height: 5em;
    display: flex;
    line-height: 2em;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1.5em;
    a{
        display: block;
        height: 100%;
        width: 100%;
      }

    .headerInfoFacebook {
      height: 40%;
      width: 25%;
      background-position: left;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../img/icon-facebook.svg');
      cursor: pointer;
    }
    .headerInfoYouTube {
      height: 40%;
      width: 25%;
      background-position: left;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../img/icon-youtube.svg');
      cursor: pointer;
    }
    .headerInfoEnglish {
      font-size: 2em;
      font-weight: bold;
      width: 25%;
      height: 40%;
      cursor: pointer;

    }
    .headerInfoNewsletter {
      line-height: 1.5em;
      border: 0.2em solid black;
      border-radius: 500em;
      text-transform: lowercase;
      text-align: center;
      align-self: flex-end;
      width: 100%;
      span {
        width: 20%;
        cursor: pointer;
      }

    }
  }
  .headerLogo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    img {
      height: 150px;
      //width: 244px;
      margin-bottom: 50px;
      margin-top: 20px;
    }
  }
  .hamburger{
    display: none;
  }
  nav{
    display: block;
    width: 95%;

  }
  .navigation{
    width: 95vw;
    max-width: 1200px;
    list-style-type: none;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;


    li {
      &:first-child{
        text-align: left;
      }
      display: inline-block;

      a {
        text-transform: uppercase;
        font-weight: bold;
        color: #000;
        text-decoration: none;
        display: inline;
        padding-bottom: 3px;
        border-bottom: 4px solid black;
        position: relative;
        &:after     {
          content: "";
          position: absolute;
          width: 100%;
          height: 4px;
          bottom: -4.5px;
          left: 0;
          background-color: #000;
          visibility: hidden;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transition: all 0.7s ease-in-out 0s;
          transition: all 0.7s ease-in-out 0s;
        }


        &:hover{
          border: none;
          width: 200%;
          &:hover:after{
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);


          }
        }
      }

    }
  }
  .navigationCooperate div {
    padding-left: 1em;
    padding-right: 1em;
    border-left: 4px solid black;
    border-right: 4px solid black;
    height: 80%;
    text-align: center;
  }
  #cooperate, #PDF{
    text-transform: none;
    border: none;
    cursor: pointer;
    text-align: center;
      &:hover:after{
        visibility: hidden;

      }
  }
  #PDF{
    color: white;


    span{
      font-weight: 100;
      font-size: 1.2em;
    }
  }

  .navigationPDF{


    div{
      //height: 25px ;
      line-height: 1em;
      padding-left: 0.4em;
      padding-right: 0.4em;
      padding-bottom: 0.2em;
      background-color: #000000;
    }
  }

  .mainSlider{
    position: relative;
  }
  .mainSliderBox {
    display: flex;
    justify-content: center;
    align-self: center;
    position: relative;
    width: 100%;

    img {
      object-fit: cover;
      height: 22em;
      width: 100%;
      border-radius: 1.3em;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0.5em;
    }
    .slick-next {

    }
    .slick-dots {
      position: absolute;
      bottom: 3em;
    }
    .mainSliderBoxContentText {
      width: 40%;
      font-size: 2em;
      border: 1px solid white;
      text-transform: uppercase;
      color: #ffffff;
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      right: 5%;
      padding: 0.1em;


    }
  }
  .sliderPrevious{
    font-size: 50px;

    position: absolute;
    top: 3.8em;
    left: 30px;
    z-index: 4;
    cursor: pointer;
    &:hover{
      color: #a8a8a8;
    }
  }
  .sliderNext{
    font-size: 50px;
    position: absolute;
    top: 3.8em;
    right: 30px;
    z-index: 4;
    cursor: pointer;
    &:hover{
      color: #a8a8a8;
    }
  }
  .mainAbout {
    width: $mainWidth;
    border-bottom: 2px solid #777777;
    margin-bottom: 2em;
    .mainAboutInfo {
      list-style-type: none;
    }
    h2 {
      text-transform: uppercase;
      margin-bottom: 1em;
    }
    li {
      line-height: 1.5em;
      margin-bottom: 0.5em;
    }
  }
  .mainAboutArrow {
    text-align: right;
    margin-bottom: 0.5em;
    a {
      font-size: 3em;
      cursor: pointer;
      &:hover{
        color: #a8a8a8;
      }
    }
  }
  .mainImages {
    width: 90vw;
    margin-bottom: 2em;
  }
  .mainImagesWrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .mainImagesEvents {
    height: 9em;
    width: 20%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2em;
    cursor: pointer;
  }

  .mainImagesEvents img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    transition: all .5s ease-in-out;
    &:hover{
      transform: scale(1.2);
    }
  }
  .mainImagesProjects {
    height: 8em;
    width: 26%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2em;
    cursor: pointer;

  }
  .mainImagesProjects img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    padding-top: 1em;
    margin-bottom: 1em;
    transition: all .5s ease-in-out;
    &:hover{
      transform: scale(1.2);
    }

  }
  .mainImagesKnow {
    height: 9em;
    width: 30%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2em;
    cursor: pointer;


    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
      padding-bottom: 1em;
      transition: all .5s ease-in-out;
      &:hover{
        transform: scale(1.2);
      }

    }
  }
  //STRONA PROJEKTÓW//
  .headerTitle {
    width: 95vw;
    line-height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
    margin-bottom: 2em;
    .hamburger {
      line-height: 1.5em;
      width: 10vw;
      display: inline-block;
      margin-right: 0.3em;
      font-weight: bold;
      font-size: 1.5em;
      text-align: center;
    }
    .headerTitleContent {
      width: 95vw;
      height: 1.5em;
      //text-align: center;
      line-height: 0.7em;
      span {
        border-bottom: 0.2em solid #000000;
        //&:before{
        //    content: '';
        //    display: block;
        //    height: 1px;
        //    width: 30vw;
        //    border-bottom: 1px solid black;
        //}

      }

    }
  }
  .headerCategories {
    width: 95vw;
    height: 2em;
    margin-bottom: -1em;

  }
  .headerCategoriesContent {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    li {
      font-size: 0.9em;
      display: inline;
      border-bottom: 0.13em solid black;
      margin-right: 0.6em;
      cursor: pointer;

    }
  }

  //GALERIA PROJEKTÓW//
  .projectsGallery {
    width: 95vw;
    margin: 0 auto;
  }
  .projectsGalleryVisible, .projectsGalleryHidden {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //flex-wrap: wrap;
    -webkit-flex-wrap: wrap;

  }

  .projectsGalleryVisibleContent, .projectsGalleryInvisibleContent {
    width: 30%;
    //height: 13em;
    margin-top: 2em;
    &:last-child {

    }

    .projectsGalleryVisibleContentImage, .projectsGalleryInvisibleContentImage {
      //height: 13.5em;
      width: 100%;

      img {
        object-fit: contain;
        width: 100%;
        border-radius: 5%;
      }
      p:nth-child(2) {
        margin-top: 10px;
        font-weight: bold;
      }
      p:nth-child(3) {
        font-size: 0.85em;

      }
    }
  }
  .projectsGalleryShowMore, .projectsGalleryShowLess{
    text-align: right;
    font-weight: 900;

    span{
      font-size: 25px;
      cursor: pointer;
      &:hover{
        color: #a8a8a8;
      }
    }
    .showMoreArrow, .showLessArrow{
      font-size: 2em;
      cursor: pointer;
      &:hover{
        color: #a8a8a8;
      }
    }
  }
  .projectsGalleryHidden{
    display: none;
  }

}