//WIDOK TABLETY
@mixin headerInfoLogos{
  height: 100%;
  width: 15%;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;

}

@media (min-width: 421px) and (max-width: 767px) {
  $mainWidth: 90vw;

  .headerSearchbar {
    width: $mainWidth;
    line-height: 2em;
    display: flex;
    align-items: center;
    .magnifyingGlass{
      display: none;
    }
    .hamburger{
      line-height: 2em;
      width: 10%;
      display: inline-block;
      margin-right: 0.3em;
      font-weight: bold;
      font-size: 2em;
      text-align: left;
    }
    input {
      display: none;

    }
  }
  #mobileMenu{
    overflow-y: scroll !important;
  }
  .mobileMenuWrapper{
    width: 100vw;
    height: 64vh;
    position: absolute;
    background-color: white;
    display: none;
    z-index: 22;


    nav{
      display: block;
      position: absolute;
      overflow-y: scroll !important;

    }
    .navigation li {
      margin-bottom: 0.6em;
      line-height: 1.2em;
      display: block;
      font-size: 1.2em;
      &:last-child{
        text-align: center;
        margin-top: 15px;
      }
      a {
        text-transform: uppercase;
        font-weight: bold;
        color: #000;
        text-decoration: none;
        display: inline;
        padding-bottom: 3px;
        border-bottom: 4px solid black;

      }
    }
    input {
      width: 85%;
      height: 2em;
    }
    #cooperate, #PDF{
      text-transform: none;
      border: none;
      cursor: pointer;
      text-align: center;
      &:hover:after{
        visibility: hidden;

      }
    }
    #PDF{
      margin-left: 10px;
      color: white;
      background-color: #000;


      span{
        font-weight: 100;
        font-size: 1.2em;
      }
    }
  }
  .headerLogo{
    display: flex;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 2em;
    width: $mainWidth;
    a{
      width: 70%;
    }
    img{
      width: 100%;
      align-self: center;
    }
  }
  .headerInfoDesktop{
    display: none;
  }
  .headerInfo{
    width: $mainWidth;
    height: 2em;
    display: flex;
    line-height: 2em;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1.5em;
    //align-items: flex-start;
     a{
        display: block;
        height: 100%;
        width: 100%;
      }
    .headerInfoFacebook{
      @include headerInfoLogos;
      background-image: url('../img/icon-facebook.svg');
    }
    .headerInfoYouTube{
      @include headerInfoLogos;
      background-image: url('../img/icon-youtube.svg');
    }
    .headerInfoEnglish{
      font-size: 2em;
      font-weight: bold;
      width: 15%;

    }
    .headerInfoNewsletter{
      line-height: 1.5em;
      border: 0.2em solid black;
      border-radius: 500em;
      text-transform: lowercase;
      text-align: center;
      align-self: flex-end;
      width: 35%;
      span{
        width: 20%
      }

    }
  }
  .mainSlider{
    position: relative;
  }
  .mainSliderBox {
    display: flex;
    justify-content: center;
    align-self: center;
    position: relative;
    width: $mainWidth;

    img{
      object-fit: cover;
      height: 22em;
      width: 100%;
      border-radius: 1.3em;
      margin-left: auto;
      margin-right: auto;
    }
    .slick-next{

    }
    .slick-dots{
      position: absolute;
      bottom: 5em;
    }
    .mainSliderBoxContentText{
      width: 60%;
      font-size: 2em;
      border: 1px solid white;
      text-transform: uppercase;
      color: #ffffff;
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      right: 5%;
      padding: 0.1em;
    }
  }
  .sliderPrevious{
    font-size: 50px;

    position: absolute;
    top: 3.8em;
    left: 30px;
    z-index: 4;
    cursor: pointer;
    &:hover{
      color: #a8a8a8;
    }
  }
  .sliderNext{
    font-size: 50px;
    position: absolute;
    top: 3.8em;
    right: 30px;
    z-index: 4;
    cursor: pointer;
    &:hover{
      color: #a8a8a8;
    }
  }
  .mainAbout{
    width: $mainWidth;
    border-bottom: 2px solid #777777;
    margin-bottom: 2em;
    .mainAboutInfo{
      list-style-type: none;
    }
    h2{
      text-transform: uppercase;
      margin-bottom: 1em;
    }
    li{
      line-height: 1.5em;
      margin-bottom: 0.5em;
    }
  }
  .mainAboutArrow {
    text-align: right;
    margin-bottom: 0.5em;
    a{
      font-size: 3em;
      cursor: pointer;
      &:hover{
        color: #a8a8a8;
      }
    }
  }
  .mainImages{
    width: 90vw;
    margin-bottom: 2em;
  }
  .mainImagesWrapper{
    display:flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .mainImagesEvents{
    height: 9em;
    width: 34%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2em;
  }

  .mainImagesEvents img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .mainImagesProjects{
    height: 8em;
    width: 26%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2em;


  }
  .mainImagesProjects img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    padding-top: 1em;
    margin-bottom: 1em;

  }
  .mainImagesKnow{
    height: 9em;
    width: 30%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2em;


    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
      padding-bottom: 1em;


    }
  }
  //STRONA PROJEKTÓW//
  .headerTitle {
    width: 90vw;
    line-height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.3em;
    margin-bottom: 1em;
    .hamburger{
      line-height: 1.5em;
      width: 10vw;
      display: inline-block;
      margin-right: 0.3em;
      font-weight: bold;
      font-size: 1.5em;
      text-align: center;
    }
    .headerTitleContent {
      width: 90vw;
      height: 1.5em;
      text-align: center;
      line-height: 0.7em;
      span{
        border-bottom: 0.2em solid #000000;
        //&:before{
        //    content: '';
        //    display: block;
        //    height: 1px;
        //    width: 30vw;
        //    border-bottom: 1px solid black;
        //}

      }

    }
  }
  .headerCategories{
    width: 90vw;
    height: 2em;
    margin-bottom: 1.5em;

  }
  .headerCategoriesContent{
    display:flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    li{
      font-size: 0.9em;
      display: inline;
      border-bottom: 0.13em solid black;
      margin-right: 0.6em;

    }
  }

  //GALERIA PROJEKTÓW//
  .projectsGallery{
    width: 95vw;
    margin: 0 auto;
  }
  .projectsGalleryVisible, .projectsGalleryHidden{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    //flex-wrap: wrap;
    -webkit-flex-wrap: wrap;


  }

  .projectsGalleryVisibleContent, .projectsGalleryInvisibleContent{
    width: 45%;
    //height: 13em;
    margin-top: 2em;

    .projectsGalleryVisibleContentImage, .projectsGalleryInvisibleContentImage{
      //height: 13.5em;
      width: 100%;


      img{
        object-fit: contain;
        width: 100%;
        border-radius: 5%;
      }
      p:nth-child(2){
        margin-top: 10px;
        font-weight: bold;
      }
      p:nth-child(3){
        font-size: 0.85em;

      }
    }
  }
  .projectsGalleryShowMore, .projectsGalleryShowLess{
    text-align: right;
    font-weight: 900;

    span{
      font-size: 25px;
      cursor: pointer;
      &:hover{
        color: #a8a8a8;
      }
    }
    .showMoreArrow, .showLessArrow{
      font-size: 2em;
      cursor: pointer;
      &:hover{
        color: #a8a8a8;
      }
    }
  }
  .projectsGalleryHidden{
    display: none;
  }
}