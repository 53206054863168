// ---------> section partners <------------


//.next{
//  background: url('../img/slider-arrow-right.svg') no-repeat;
//    }
//.prev{
//     background: url('../img/slider-arrow-left.svg') no-repeat;
//  }
    

section#partners {
  margin-top: 50px;
  border-top: 4px solid #000;
  border-bottom: 4px solid #000;
}

.sponsors--sliderbox, .friends--sliderbox {
  display: none;
}

.partners--sliderbox, .sponsors--sliderbox, .friends--sliderbox {
  margin-top: 30px;
  width: 90%;
  height: 100px;  
  // height: 140px;
  float: left; 
  // background: green;
    img {
      margin-right: 4vw; // na pełnym ekranie 65px      
      width: auto;
      height: 80px;
      display: inline-block;
    }
   .title {     
     margin: 30px;
     margin-top: 25px;
     margin-left: 90px;
     padding-left: 40px;
     font-size: 27px;
     
     font-weight: 700;
     font-style: italic;

   }
    
}

section#partners .slider--arrowContainer {
  z-index: 1;
  width: 5%;
  height: 140px;
  float: left;
  position: relative;
  //overflow: hidden;
  // background: red;  
}

section#partners .slider-arrow {
  width: 90%;
  min-width: 45px;
  //height: 30px;
  position: absolute;
  bottom: 50%;
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
  cursor: pointer;
}


section#partners .next{ 
  float: right;         
  right: 0;
    }

// ---------> section contact <------------

section#contact {
  // height: 440px;
  height: 100%;
  margin-top: 40px;
  position: relative;
}

.iframe--googlemap {
  width: 100%;
  height: 57vh;
  border: 0; 
  pointer-events: none; // wyłącza wszelkie akcje z Mapy
  // position: relative;
  object-fit: center;
}

@media only screen 
and (max-device-width : 768px) 
and (orientation : landscape) {
  .iframe--googlemap {
    height: 90vh;
  }
}

//  div contactowy

.div--contact {
  // width: 215px;
  width: 100%;
  height: 100%;
  padding-top: 20px; // na pełnym ekranie 37px
  padding-left: 44px;
  padding-right: 37px;
  border-radius: 20px;
  background-color: #FFFFFF;
  font-size: 16px;  
  font-weight: 100;

}

.p--contact-heading {
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.p--contact-tiny {
  margin-top: 10px;
  text-align: center;
}


// koniec div kontaktowy

.p--footer {
  // margin-top: 20px;
  width: 100%;
  float: right;
  font-size: 12px;
  text-align: right;

}
.sliderPrevFooter, .sliderNextFooter{
  font-size: 2.5em;
  cursor: pointer;
  &:hover{
    color: #a8a8a8;
  }
}

@media screen and (min-width: 768px) {
    .div--contact {
      width: 330px;
      height: 465px;
      padding-top: 20px; // na pełnym ekranie 37px
      padding-left: 30px;
      padding-right: 30px;
      border-radius: 20px;
      background-color: #FFFFFF;
      opacity: 0.9;
      font-size: 16px;  
      font-weight: 100;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;  
      margin-right: 3vw;
      line-height: 24px;
  }
}
