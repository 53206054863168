
@import "header";
@import "footer";

section, footer {
    width: 100%;   
}

section > h2 {
  border-bottom: 6px solid #000;
  display: inline;
  text-transform: uppercase;
  font-size: 30px;  
}

a {
  color: #000;
  text-decoration: none; /* no underline */
}
$highlight-a: #8C8C8C;

a:hover {
  color: $highlight-a;
}

@import "about";
    
// ---------> section events/projekty <------------

section#events {
  margin-top: 35px;
  // padding: 2vw;

  li {
    margin-right: 30px;
    display: inline-block;
    list-style: none;
    text-decoration: underline;
    font-size: 1.1em;
    font-style: italic;
  }
}

.events--content {
  margin-top: calc(6vw + 10px); 
  vertical-align:top;
    a:hover + .event--content-descrip h3 {
      color: $highlight-a;
    }

    h3 {
        margin-top: calc(2vw);        
    }

    time {      
      margin-top: 4px;
      display: block;
    }

    p.event--content-tags {     
      margin-top: 4px;
      font-style: italic;
    } 
     img {
      display: block;
      margin-left: auto;
      margin-right: auto;   
      height: 60vw;
      width: 90vw;
      object-fit: cover;
      border-radius: 30px;  
      // background-color: #000;    
    }
    .event--content-descrip {
      width: 90vw;
      display: block;
      margin-left: auto;
      margin-right: auto; 
      text-align: justify;
    }    
}

div.pagination-arrow--prev,  div.pagination-arrow--next {  
  margin-top: 20px;
  display: inline-block; 
  // width: 40%;
  }

div.pagination-arrow--next  {
  float: right;
  display: inline-block;  
}
div.pagination-arrow--next:after, div.pagination-arrow--next:before {
  display: table;
  content: '';
  clear: both;
}


@media screen and (min-width: 480px) {
  section#events {
  padding: 0;
  // padding-left: 3vw;
}
  .events--content {
    display: flex;
    margin-right: 3vw;
    h3 {
        margin-top: 0;   
        text-align: left;     
    }
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;   
      height: 30vw;
      width: 43vw;
      border-radius: 30px; 
    }
    .event--content-descrip {
      width: 43vw;
      display: block;
      margin-left: auto;
      margin-right: auto; 
      text-align: justify;
    }
}
}

@media screen and (min-width: 768px) {
  section#events {
  padding: 0;
  padding-left: 3vw;
}
  .events--content {
    display: inline-block;
    margin-right: 2vw;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;   
      height: 20vw;
      width: 29vw;
      border-radius: 30px; 
    }
    .event--content-descrip {
      width: 29vw;
      display: block;
      margin-left: auto;
      margin-right: auto; 
      text-align: justify;
    }
}
}

@media screen and (min-width: 1024px) {
  section#events {
    padding: 0;
  }
  .events--content:nth-child(3n) {    
    margin-right: 0;
    }
  
  .events--content {
    display: inline-block;
    margin-right: 35px;
    margin-top: 40px;  
      h3 {
        margin-top: 7px;
      }
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;   
        height: 21vw;
        width: 27vw;
        max-width: 358px;
        max-height: 253px;
        border-radius: 30px; 
      }
      .event--content-descrip {
        width: 27vw;
        max-width: 341px;
        display: block;
        margin-left: auto;
        margin-right: auto; 
        text-align: justify;
      }
}
}
 @media screen and (min-width: 1219px) {
   .events--content:nth-child(3n) {    
    margin-right: 0;
    }
  .events--content {      
      margin-right: 58px;     
  }
 }

// ---------> section events-more <------------

section#events-more {
  margin-top: 30px;
  padding: 3vw;
}

.events-more--wrapper {
  margin-top: 40px;
  h1 {
    text-transform: uppercase;
    font-size: 34px;
    font-weight: 900;
  }
  p.events-more--data {  
    font-size: 28px;
    font-weight: 900;
  }
  p.events-more--descript {
    margin-top: 25px;
    font-size: 16px;
  }
}

// ---------> section events-more gallery <------------

section#events-gallery {
  margin-top: 40px;
}

div.events-gallery--wrapper {  
   img {
   margin-left: auto;
   margin-right: auto;
   margin-top: 40px;
   display: block;
   width: 90vw;
   height: 61vw;
   object-fit: cover;
   transition: all .5s ease-in-out;    
  }  
}

// ---------> section events-more video <------------
section#events-video {
  margin-top: 40px;
}

div.events-video--wrapper {   
   iframe {
   margin-left: auto;
   margin-right: auto;
   margin-top: 40px;
   display: block;
   width: 91vw;
   height: 61vw;    
  }    
}

// ---------> section events-more patroni wydarzenia <------------
section > h3 {
  border-bottom: 6px solid #000;
  display: inline;
  text-transform: uppercase;
  font-size: 22px;  
}

div.events-patrons--wrapper {  
   img {  
   margin-right: 15px;
   margin-top: 40px;
   display: inline-block;
   width: auto;
   max-width: 80vw;
   height: 30vw;
   object-fit: contain;
  }  
}


@import 'phonesMichal.scss';

@media screen and (min-width: 480px) {
  section#events-gallery {
  margin-top: 40px;
  padding: 3vw;
  }

  div.events-gallery--wrapper {       
      img {
        margin-right: calc(2vw + 7px);
        margin-top: 40px;
        display: inline-block;
        width: 43vw;
        height: 30vw;    
      }
      a:nth-child(2n) {   
        img {
          margin-right: 0;
        }      
      }
      img:hover{
      transform: scale(1.6);
    }
  }
  section#events-video {
     margin-top: 40px;
     padding: 3vw;
  }

  div.events-video--wrapper {   
    iframe {
          margin-right: calc(2vw + 7px);
          margin-top: 40px;
          display: inline-block;
          width: 43vw;
          height: 30vw;    
        }
        a:nth-child(2n) {   
          iframe {
            margin-right: 0;
          }      
        }
    }

    div.events-patrons--wrapper {  
      img {  
      margin-right: 15px;
      margin-top: 40px;
      display: inline-block;
      width: auto;
      max-width: 34vw;
      height: 18vw;
      object-fit: contain;
      transition: all .5s ease-in-out;    
      }  
}
}
@import 'tabletsMichal.scss';

@media screen and (min-width: 768px) {
  section#events-gallery {
  margin-top: 40px;
  padding: 3vw;
  }

  div.events-gallery--wrapper {       
      img {
        margin-right: 3vw;
        margin-top: 40px;
        display: inline-block;
        width: 28vw;
        height: 20vw;    
      }
      a:nth-child(2n) {   
        img {
          margin-right: 3vw;
        }      
      }
      a:nth-child(3n) {   
        img {
          margin-right: 0;
        }      
      }
  }
  section#events-video {
    margin-top: 40px;
    padding: 3vw;
  }

  div.events-video--wrapper {       
      iframe {
        margin-right: 3vw;
        margin-top: 40px;
        display: inline-block;
        width: 28vw;
        height: 20vw;    
      }
      a:nth-child(2n) {   
        iframe {
          margin-right: 3vw;
        }      
      }
      a:nth-child(3n) {   
        iframe {
          margin-right: 0;
        }      
      }
}
 div.events-patrons--wrapper {  
      img {  
      margin-right: 25px;
      margin-top: 40px;
      display: inline-block;
      width: auto;
      max-width: 300px;
      height: 120px;
      object-fit: contain;
      transition: all .5s ease-in-out;    
      }  
}
}
@import 'smallerDesktopMichal';

@media screen and (min-width: 1024px) {
    section#events-more {
      padding: 0;
    }

  section#events-gallery {
  margin-top: 40px;
  padding: 0;
  }

  div.events-gallery--wrapper {       
      img {
        margin-right: 15px;
        margin-top: 40px;
        display: inline-block;
        width: 18vw;
        height: 12vw; 
        max-width: 224px;
        max-height: 149px;   
      }
      a {   
        img:nth-child(n) {
          margin-right: 15px;
        }      
      }
      a:nth-child(5n) {   
        img {
          margin-right: 0;
        }      
      }
    
  }
  section#events-video {
    margin-top: 40px;
    padding: 0;
  }

  div.events-video--wrapper {       
      iframe {
        margin-right: 15px;
        margin-top: 40px;
        display: inline-block;
        width: 18vw;
        height: 12vw; 
        max-width: 224px;
        max-height: 149px;   
      }
      a {   
        iframe:nth-child(n) {
          margin-right: 15px;
        }      
      }
      a:nth-child(5n) {   
        iframe {
          margin-right: 0;
        }      
      }
    
  }
}
@import "desktopMichal";

